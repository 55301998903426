import { LanguageSelector } from '../../../shared/components/language-selector'
import { PageContainer } from '../../../shared/components/page-container'
import { BodySmallRegular } from '../../../shared/components/typography'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { getHrefFromStoryblokLink } from '../../../storyblok/utils/getHrefFromStoryblokLink'
import { BottomLinks } from '../bottom-links'
import { Contact } from '../contact'
import { Sitemap } from '../sitemap'
import { SocialIcon } from '../social-icon'

import {
  Footer as FooterOuter,
  Top,
  Divider,
  Socials,
  Bottom,
  FooterLogo,
  BottomLeft,
  StLinksContainer,
  StCreatorLink,
} from './Footer.styled'

import type { StoryblokFooter } from '../../../storyblok/components/footer/Footer.types'

type FooterProps = {
  className?: string
  content?: StoryblokFooter
  locale?: string
  locales?: string[]
}

export const Footer = ({
  className,
  content,
  locale,
  locales,
}: FooterProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  return (
    <FooterOuter className={className}>
      <PageContainer>
        <Top>
          <Socials>
            {content?.social_media_links.map((social) => {
              return (
                <SocialIcon
                  key={social._uid}
                  image={social.image}
                  url={getHrefFromStoryblokLink(social.link)}
                  title={social.link.title}
                />
              )
            })}
          </Socials>
          {locale && locales && locales.length > 0 && (
            <LanguageSelector currentLanguage={locale} options={locales} />
          )}
        </Top>
        <Divider></Divider>
        <Sitemap content={content?.sitemap} />
        <Bottom>
          <BottomLeft>
            {content?.logo && content.logo.length > 0 && (
              <a
                href={getHrefFromStoryblokLink(content.logo[0].link)}
                title={content.logo[0].link.title}
              >
                <FooterLogo
                  image={content.logo[0].image}
                  width={48}
                  height={52}
                />
              </a>
            )}
            <Contact
              company_name={content?.company_name}
              company_address={content?.company_address}
              contact_email={content?.contact_email}
              contact_phone={content?.contact_phone}
            />
          </BottomLeft>
          <StLinksContainer>
            <BottomLinks links={content?.bottom_links ?? []}></BottomLinks>
            <BodySmallRegular>
              {t('created_by')}{' '}
              <StCreatorLink href="https://nimblestudio.com">
                Nimble
              </StCreatorLink>
            </BodySmallRegular>
          </StLinksContainer>
        </Bottom>
      </PageContainer>
    </FooterOuter>
  )
}
